$(document).ready(function () {
	var $formCadastroNewsletter = $('#cadNewsletterForm');

	if ($formCadastroNewsletter.length) {
		var $email_cad = $formCadastroNewsletter.find('[name=email_cad]'),
			$tipo_usuario_cad = $formCadastroNewsletter.find('[name=tipo_usuario_cad]'),
			$fields = $('.field-newslleter'),
			$privacy_policy = $formCadastroNewsletter.find('[name=privacy_policy]'),
			$submit_cad = $formCadastroNewsletter.find('[type=submit]'),
			$spacer_cad = $formCadastroNewsletter.find('.text-spacer'),
			$success_cad = $formCadastroNewsletter.find('.alert-success'),
			$error_cad = $formCadastroNewsletter.find('.alert-danger'),
			formEnabledCadastroNewsletter = true;

		function initCadastroNewsletter() {
			$spacer_cad.show();
			$success_cad.hide();
			$error_cad.hide();

			changeFields();

			$formCadastroNewsletter.submit(function (event) {
				event.preventDefault();
				onSubmitCadastroNewsletter();
			});
		}

		function changeFields() {
			$fields.on("input", function () {
				const email = $email_cad.val();
				const userType = $tipo_usuario_cad.val();
				const privacyPolicy = (($privacy_policy.is(":checked")) ? 1 : 0);

				if (email && userType && privacyPolicy) {
					$error_cad.hide();
				}
			});
		}

		function setFormEnabledCadastroNewsletter(enabled) {
			formEnabledCadastroNewsletter = enabled;

			$email_cad.prop('disabled', !enabled);
			$tipo_usuario_cad.prop('disabled', !enabled);
			$privacy_policy.prop('disabled', !enabled);
		}

		function showErrorsCadastroNewsletter(errors) {
			$spacer_cad.hide();
			$error_cad.show();

			if (errors.errors.privacy_policy) {
				$('#label-privacy_policy').addClass('label_privacy_policy_error');
			}
		}

		function showSuccessCadastroNewsletter() {
			$spacer_cad.hide();
			$success_cad.show().delay(2000).fadeOut(3000, function () { $spacer_cad.show(); });
		}

		$privacy_policy.change(function () {
			if ($(this).is(":checked")) {
				$('#label-privacy_policy').removeClass('label_privacy_policy_error');
			} else {
				$('#label-privacy_policy').addClass('label_privacy_policy_error');
			}
		});

		function onSubmitCadastroNewsletter() {
			var urlCad, dataCad;

			if (!formEnabledCadastroNewsletter) return;

			urlCad = $formCadastroNewsletter.attr('action');

			dataCad = {
				email_cad: $email_cad.val(),
				tipo_usuario_cad: $tipo_usuario_cad.val(),
				privacy_policy: (($privacy_policy.is(":checked")) ? 1 : 0),
			};

			setFormEnabledCadastroNewsletter(false);

			$spacer_cad.show();
			$success_cad.hide();
			$error_cad.hide();

			$.ajax({
				url: urlCad,
				data: dataCad,
				dataType: 'json',
				type: 'post',
				success: function (response, textStatus) {
					if (response.exception) {
						$spacer_cad.hide();
						$error_cad.html('Ocorreu um erro com a requisição.').show();
					} else {
						$error_cad.html('Preencha todos os campos.');

						if (response.jaCadastrado) {
							$error_cad.html('E-mail já cadastrado.');

							$spacer_cad.hide();
							$error_cad.show();
						}
						else {
							showSuccessCadastroNewsletter();

							$email_cad.val('');
							$tipo_usuario_cad.val('');
							$privacy_policy.prop('checked', false);
							$('#container_tipo_usuario').find('.selectSpan').html('Selecione');
						}
					}
				},
				error: function (response, textStatus, error) {
					showErrorsCadastroNewsletter(response.responseJSON);
				},
				complete: function () {
					setFormEnabledCadastroNewsletter(true);
				}
			});
		}

		initCadastroNewsletter();
	}
});