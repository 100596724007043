$(document).ready(function()
{
	let $blockAjax  			= $('.matcBlockAjax');
	let $gridRegistros 			= $('#listagemCanalAtlas');
	let $routeLoadPaginacao 	= $('#rtLoadPaginacao');
	let $btnPaginacaoRegistros 	= $('#carregar-mais-postagens');

	if ($blockAjax.length)
	{
		$blockAjax.matchHeight()
	}
	
	if ($gridRegistros.length && $routeLoadPaginacao.length)
	{
		let $idiomaAtual 		= $('#rtIdiomaAtual');
		let	$categoriaAtual 	= $('#rtCategoriaAtual');
		let $idExcluirAtual 	= $('#rtIdExcluirAtual');
		let	$totalPaginas 		= $('#rtTotalPaginas');
		let	$modoAtual 			= $('#rtModoAtual');
		let	$btnCategoriaVideos = $('#btn-categoria');
		let	$categoriaMudou 	= false;
		let	$paginaAtual 		= 1;
		let	$containerPosts 	= $('.masonry-grid');
		let	$containerVideos 	= $('.timeOuter');
		let	$container 			= '';
	    
	    function loadRegistrosPaginacao()
	    {
			let url = $routeLoadPaginacao.val();
			let dataPaginacao = {
				idioma: 			$idiomaAtual.val(),
				slugCategoria: 		$categoriaAtual.val(),
				idExcluirPaginacao: $idExcluirAtual.val(),
				pagina: 			$paginaAtual,
				canalModoAtual: 	$modoAtual.val(),
				mudancaCategoria: 	$categoriaMudou
			};
			
			if ($modoAtual === 'videos')
			{
		        $blockAjax.matchHeight();
		        $container = $containerVideos;
			}
			else
			{
		        $container = $containerPosts;
			}
			
			$.ajax({
				url: url, 
				data: dataPaginacao, 
				dataType: 'json',
				type: 'post',
				success: function(response, textStatus) {
					if (response.htmlAppend)
					{
		    			if ($modoAtual.val() === 'videos' && $categoriaMudou) { $gridRegistros.html(''); }
		    			
			        	$gridRegistros.append(response.htmlAppend);
			        	
			        	if (response.numTotalPaginas) { $totalPaginas.val(response.numTotalPaginas); }
			        	
				        if ($paginaAtual == $totalPaginas.val())
				        {
				        	$btnPaginacaoRegistros.hide();
				        }
				        else
				        {
				        	$btnPaginacaoRegistros.show();
				        }
			            
						if ($modoAtual.val() === 'videos') { $blockAjax.matchHeight(); }

			            $container.imagesLoaded(function()
			            {
			    			if ($modoAtual.val() === 'videos')
			    			{
				                $blockAjax.matchHeight();
			    			}
			    			else
			    			{
				                $container.masonry({ transitionDuration: '0.3s' });
				                $container.masonry('reloadItems');
				                $container.masonry('layout');
			    			}			                
			            });
			            
			            $categoriaMudou = false;			            
					}
				},
			    error: function (response, textStatus, error) {
			    },
			    complete: function() {
					if ($modoAtual.val() === 'videos')
					{
						setTimeout(() => {
							$('.matcBlockAjax').matchHeight();
						}, 500)
					}
			    }
			});
	    }
	    
	    $btnCategoriaVideos.click(function(e)
	    {
	        e.preventDefault();
	        
	        $categoriaMudou = true;
	        $paginaAtual = 1;
	        $categoriaAtual.val($('#categoria-video').val());
	        
	        loadRegistrosPaginacao();
	    });

		$btnPaginacaoRegistros.click(function(e)
	    {
	    	$paginaAtual = $paginaAtual + 1;
	        
	        loadRegistrosPaginacao();	        
	    });
	    
	    //Efeitos de carregamento e transição	
	    $containerPosts.masonry({ itemSelector: '.masonry-entry' });	
	    $containerPosts.imagesLoaded(function()
	    {
	    	$containerPosts.masonry({ transitionDuration: '0.3s' });
	    	$containerPosts.masonry( 'reloadItems' );
	    	$containerPosts.masonry( 'layout' );
	    });
	}
});