require('./app-busca-produtos.js');
require('./app-home-cadastro-newsletter.js');
require('./app-contato-mercado-interno.js');
require('./app-contato-mercado-externo.js');
require('./app-canal-atlas.js');

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function() {
    //BANNER
    if (window.Rocket != undefined) {
        Rocket.flicker({
            selector: '.flicker',
            animation: 'transition-fade',
            autoFlick: true,
            dotAlignment: 'center',
            autoFlickDelay: '2',
            arrows: 'true'
        });
    } 
});