$(document).ready(function () {
	var $formFaleConoscoMI = $('#contatoMIForm');
	
	if ($formFaleConoscoMI.length) {
		var $area_interesse = $formFaleConoscoMI.find('[name=area_interesse]'),
			$nome = $formFaleConoscoMI.find('[name=nome]'),
			$email = $formFaleConoscoMI.find('[name=email]'),
			$telefone = $formFaleConoscoMI.find('[name=telefone]'),
			$estado = $formFaleConoscoMI.find('[name=estado]'),
			$cidade = $formFaleConoscoMI.find('[name=cidade]'),
			$mensagem = $formFaleConoscoMI.find('[name=mensagem]'),
			$receber_newsletter = $formFaleConoscoMI.find('[name=receber_news]'),
			$privacy_policy = $formFaleConoscoMI.find('[name=privacy_policy]'),
			$routeLoadCidades = $('#rtLoadCidades'),
			$msgErroValidacao = $('#strMsgErroValidacao'),
			$msgErroRequisicao = $('#strMsgErroRequisicao'),
			$strCampoSelecione = $('#strCampoSelecione'),
			$strCampoSelecioneEstado = $('#strCampoSelecioneEstado'),
			$containerAreaInteresse = $formFaleConoscoMI.find('[id=container_form_area_interesse]'),
			$containerNome = $formFaleConoscoMI.find('[id=container_form_nome]'),
			$containerEmail = $formFaleConoscoMI.find('[id=container_form_email]'),
			$containerTelefone = $formFaleConoscoMI.find('[id=container_form_telefone]'),
			$containerEstado = $formFaleConoscoMI.find('[id=container_form_estado]'),
			$containerCidade = $formFaleConoscoMI.find('[id=container_form_cidade]'),
			$containerMensagem = $formFaleConoscoMI.find('[id=container_form_mensagem]'),
			$submit = $formFaleConoscoMI.find('[type=submit]'),
			$spacer = $formFaleConoscoMI.find('.text-spacer'),
			$success = $formFaleConoscoMI.find('.text-success'),
			$error = $formFaleConoscoMI.find('.text-error'),		
			$alertErrors = $formFaleConoscoMI.find('.alert-error-contact'),
			$captcha = $('.error-captcha')

			formEnabledFaleConosco = true;

		function initFaleConosco() {
			$spacer.show();
			$success.hide();
			$error.hide();
			$alertErrors.hide()
			$captcha.hide()

			$formFaleConoscoMI.submit(function (event) {
				event.preventDefault();
				onSubmitFaleConosco();
			});
		}

		function setFormEnabledFaleConosco(enabled) {
			formEnabledFaleConosco = enabled;

			$area_interesse.prop('disabled', !enabled);
			$nome.prop('disabled', !enabled);
			$email.prop('disabled', !enabled);
			$telefone.prop('disabled', !enabled);
			$estado.prop('disabled', !enabled);
			$cidade.prop('disabled', !enabled);
			$mensagem.prop('disabled', !enabled);
			$receber_newsletter.prop('disabled', !enabled);
			$privacy_policy.prop('disabled', !enabled);
			$submit.prop('disabled', !enabled);
		}

		function showErrorsFaleConosco(errors) {
			const errorList = []
		
			for (let attribute in errors.errors) {
				$containerCampo = $formFaleConoscoMI.find('[id=container_form_' + attribute + ']');
				$containerCampo.addClass('cml_error_class_cads');

				errorList.push(...errors.errors[attribute])
			}
			
			if (errors.errors.privacy_policy) {
				$formFaleConoscoMI.find('.label_privacy_policy').addClass('label_privacy_policy_error');
			}

			let errorListHtml = ''

			errorList.forEach(errorMessage => {
				errorListHtml += `<li>* ${errorMessage}</li>`
			})
		
			$('.alert-error-contact-list').append(errorListHtml)

			$spacer.hide();
			$error.show();
			$alertErrors.show()
		}

		function showSuccessFaleConosco() {
			$spacer.hide();
			$success.show();
			$alertErrors.hide()

			$('.alert-error-contact-list').html('')
		}

		$privacy_policy.change(function () {
			if ($(this).is(":checked")) {
				$formFaleConoscoMI.find('.label_privacy_policy').removeClass('label_privacy_policy_error');
			} else {
				$formFaleConoscoMI.find('.label_privacy_policy').addClass('label_privacy_policy_error');
			}
		});

		$estado.change(function () {
			var url, data, htmlCidades;

			if (!formEnabledFaleConosco) return;

			url = $routeLoadCidades.val();
			$containerCidade.find('.selectSpan').html($strCampoSelecioneEstado.val());
			htmlCidades = '<option value="">' + $strCampoSelecioneEstado.val() + '</option>';

			//Limpa a classe de erro (se presente no momento)
			$containerCidade.removeClass('cml_error_class_cads');

			data = { estado: $estado.val() };

			$.ajax({
				url: url,
				data: data,
				dataType: 'json',
				type: 'post',
				success: function (response, textStatus) {
					if (response.exception) {
						console.log(response.exception);
					} else {
						if (response.cidades) {
							$containerCidade.find('.selectSpan').html($strCampoSelecione.val());
							htmlCidades = '<option value="">' + $strCampoSelecione.val() + '</option>';

							for (cont = 0; cont < response.cidades.length; cont++) {
								htmlCidades += '<option value="' + response.cidades[cont].nome + '">' + response.cidades[cont].nome + '</option>';
							}
						}

						$cidade.html(htmlCidades);
						$cidade.val('');
					}
				},
				error: function (response, textStatus, error) {
					$cidade.html(htmlCidades);
					$cidade.val('');
				},
				complete: function () {
					$cidade.prop('disabled', false);
				}
			});
		});

		function onSubmitFaleConosco() {
			var url, data;

			if (!formEnabledFaleConosco) return;

			url = $formFaleConoscoMI.attr('action');

			const responseRecaptcha = grecaptcha.getResponse();

			if (responseRecaptcha.length == 0) {
				$captcha.show();

				return
			}

			$captcha.hide();

			//Limpa as classes de erro (se presentes no momento)
			$containerAreaInteresse.removeClass('cml_error_class_cads');
			$containerNome.removeClass('cml_error_class_cads');
			$containerEmail.removeClass('cml_error_class_cads');
			$containerTelefone.removeClass('cml_error_class_cads');
			$containerEstado.removeClass('cml_error_class_cads');
			$containerCidade.removeClass('cml_error_class_cads');
			$containerMensagem.removeClass('cml_error_class_cads');

			data = {
				area_interesse: $area_interesse.val(),
				nome: $nome.val(),
				email: $email.val(),
				telefone: $telefone.val(),
				estado: $estado.val(),
				cidade: $cidade.val(),
				mensagem: $mensagem.val(),
				receber_newsletter: (($receber_newsletter.is(":checked")) ? 1 : 0),
				privacy_policy: (($privacy_policy.is(":checked")) ? 1 : 0),
			};

			setFormEnabledFaleConosco(false);

			$spacer.show();
			$success.hide();
			$error.hide();
			$alertErrors.hide()

			$('.alert-error-contact-list').html('')

			$.ajax({
				url: url,
				data: data,
				dataType: 'json',
				type: 'post',
				success: function (response, textStatus) {
					if (response.exception) {
						$spacer.hide();
						$error.html($msgErroRequisicao.val()).show();
					} else {
						$error.html($msgErroValidacao.val());
						showSuccessFaleConosco();

						$area_interesse.val('');
						$nome.val('');
						$email.val('');
						$telefone.val('');
						$estado.val('');
						$cidade.val('');
						$mensagem.val('');
						$receber_newsletter.prop('checked', false);
						$privacy_policy.prop('checked', false);

						$containerAreaInteresse.find('.selectSpan').html($strCampoSelecione.val());
						$containerEstado.find('.selectSpan').html($strCampoSelecione.val());
						$containerCidade.find('.selectSpan').html($strCampoSelecioneEstado.val());
					}
				},
				error: function (response, textStatus, error) {
					showErrorsFaleConosco(response.responseJSON);
				},
				complete: function () {
					setFormEnabledFaleConosco(true);
				}
			});
		}

		initFaleConosco();
	}
});