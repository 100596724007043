$(document).ready(function()
{
	var $formBusca = $('#buscaForm');
	
	if ($formBusca.length)
	{
		var $termoBusca = $formBusca.find('[name=termo_busca]'),
			$idiomaBusca = $formBusca.find('[name=idioma_busca]'),
			$containerResultadosBusca = $('#containerResultadosBusca'),
			$boxListaResultadosBusca = $('#boxListaResultadosBusca'),
			$reqXHR = false;
		
		function initBusca()
		{
		    if ($reqXHR)
		    {
		    	$reqXHR.abort();
		    }
		    
			$containerResultadosBusca.css('display', 'none');
		}
		
		$formBusca.submit(function(event)
		{
		    if ($termoBusca.val().length == 0)
		    {
				event.preventDefault();
				return false;
		    }
		});	
		
		$(".box_busca").on("click", ".btnShowResultadosBusca", function(e) {
		    e.preventDefault();
		    $formBusca.submit();
		});		
		
		$termoBusca.keyup(function()
		{
		    if ($reqXHR)
		    {
		    	$reqXHR.abort();
		    }
		    
		    if ($termoBusca.val().length >= 2)
		    {
				var url = '', data = '', verTodosBtn = '', htmlHost = '', htmlResult = '';			
				url = $formBusca.attr('action');
				
				data = {
					idioma_busca: $idiomaBusca.val(),
					termo_busca: $termoBusca.val(),
					modo_busca: 'onTyping',
				};
		
		    	$boxListaResultadosBusca.html('');
				$containerResultadosBusca.css('display', 'none');
				
				$reqXHR = $.ajax({
					url: url, 
					data: data, 
					dataType: 'json',
					type: 'get',
					success: function(response) {
						if (response.exception)
						{
					    	$boxListaResultadosBusca.html('');
					    	$containerResultadosBusca.css('display', 'none');
						}
						else
						{
							if (response.success)
							{
								if ($idiomaBusca.val() == 'pt') { verTodosBtn = 'Ver todos Resultados' }
								else if ($idiomaBusca.val() == 'es') { verTodosBtn = 'Ver todos los resultados' }
								else { verTodosBtn = 'Show all results' }
								
								if (window.location.host == 'localhost' || window.location.host == 'dev' || window.location.host == '192.168.254.3')
								{
									htmlHost = window.location.origin + '/atlas/2017_portal_atlas/';
								}
								else
								{
									htmlHost = window.location.origin + '/';								
								}
									
								for (cont = 0; cont < response.dados.length; cont++)
								{
			                    	htmlResult += '<li>' +
                                    			  '<a href="' + response.dados[cont].link + '">' +
                                    			  '<figure>';
												  if (response.dados[cont].imagem_produto)
												  {
													  htmlResult += '<img src="' + htmlHost + 'public/' + response.dados[cont].imagem_produto + '" width="70" style="width: auto; height: 70px;">';
												  }
												  else
												  {
													  htmlResult += '<img src="' + htmlHost + 'public/' + 'images/not-found/70-70/70-70.jpg">';
												  }
									htmlResult += '</figure>' +
												  '<span>' +
												  '<h6>' + response.dados[cont].referencia_principal_site + '</h6>' +
												  '<small>' + response.dados[cont].nome_idioma + '</small>' +
												  '</span>' +
												  '</a>' +
												  '</li>';                               
								}
								
								htmlResult += '<li>' +
											  '<a href="' + $idiomaBusca.val() + '/busca" class="btnShowResultadosBusca">' +
											  '<span style="text-align: center; display: inline-block; width:100%">' +
											  '<h6>' + verTodosBtn + '</h6>'
											  '</span>' +
											  '</a>' +
											  '</li>';                               
									
								$boxListaResultadosBusca.html(htmlResult);							
						    	$containerResultadosBusca.css('display', 'block');
							}
							else
							{
						    	$boxListaResultadosBusca.html('');
						    	$containerResultadosBusca.css('display', 'none');
							}
						}
					},
				    error: function (response, error) {
				    	$boxListaResultadosBusca.html('');
				    	$containerResultadosBusca.css('display', 'none');
				    }
				});
		    }
		    else
		    {
		    	$containerResultadosBusca.css('display', 'none');        	
		    }
		});	
	
		initBusca();
	}	
});