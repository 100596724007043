$(document).ready(function () {
	var $formFaleConoscoME = $('#contatoMEForm');

	if ($formFaleConoscoME.length) {
		var $nome = $formFaleConoscoME.find('[name=nome]'),
			$email = $formFaleConoscoME.find('[name=email]'),
			$telefone = $formFaleConoscoME.find('[name=telefone]'),
			$cidade = $formFaleConoscoME.find('[name=cidade]'),
			$estado = $formFaleConoscoME.find('[name=estado]'),
			$pais = $formFaleConoscoME.find('[name=pais]'),
			$mensagem = $formFaleConoscoME.find('[name=mensagem]'),
			$privacy_policy = $formFaleConoscoME.find('[name=privacy_policy]'),
			$msgErroValidacao = $('#strMsgErroValidacao'),
			$msgErroRequisicao = $('#strMsgErroRequisicao'),
			$strCampoSelecione = $('#strCampoSelecione'),
			$strCampoSelecioneEstado = $('#strCampoSelecioneEstado'),
			$containerNome = $formFaleConoscoME.find('[id=container_form_nome]'),
			$containerEmail = $formFaleConoscoME.find('[id=container_form_email]'),
			$containerTelefone = $formFaleConoscoME.find('[id=container_form_telefone]'),
			$containerCidade = $formFaleConoscoME.find('[id=container_form_cidade]'),
			$containerEstado = $formFaleConoscoME.find('[id=container_form_estado]'),
			$containerPais = $formFaleConoscoME.find('[id=container_form_pais]'),
			$containerMensagem = $formFaleConoscoME.find('[id=container_form_mensagem]'),
			$submit = $formFaleConoscoME.find('[type=submit]'),
			$spacer = $formFaleConoscoME.find('.text-spacer'),
			$success = $formFaleConoscoME.find('.text-success'),
			$error = $formFaleConoscoME.find('.text-error'),
			$alertErrors = $formFaleConoscoME.find('.alert-error-contact'),
			$captcha = $('.error-captcha')

			formEnabledFaleConosco = true;

		function initFaleConosco() {
			$spacer.show();
			$success.hide();
			$error.hide();
			$alertErrors.hide()
			$captcha.hide()

			$formFaleConoscoME.submit(function (event) {
				event.preventDefault();
				onSubmitFaleConosco();
			});
		}

		function setFormEnabledFaleConosco(enabled) {
			formEnabledFaleConosco = enabled;

			$nome.prop('disabled', !enabled);
			$email.prop('disabled', !enabled);
			$telefone.prop('disabled', !enabled);
			$cidade.prop('disabled', !enabled);
			$estado.prop('disabled', !enabled);
			$pais.prop('disabled', !enabled);
			$mensagem.prop('disabled', !enabled);
			$privacy_policy.prop('disabled', !enabled);
			$submit.prop('disabled', !enabled);
		}

		function showErrorsFaleConosco(errors) {
			const errorList = []
		
			for (let attribute in errors.errors) {
				$containerCampo = $formFaleConoscoME.find('[id=container_form_' + attribute + ']');
				$containerCampo.addClass('cml_error_class_cads');

				errorList.push(...errors.errors[attribute])
			}
			
			if (errors.errors.privacy_policy) {
				$formFaleConoscoME.find('.label_privacy_policy').addClass('label_privacy_policy_error');
			}

			let errorListHtml = ''

			errorList.forEach(errorMessage => {
				errorListHtml += `<li>* ${errorMessage}</li>`
			})
		
			$('.alert-error-contact-list').append(errorListHtml)

			$spacer.hide();
			$error.show();
			$alertErrors.show()
		}

		$privacy_policy.change(function () {
			if ($(this).is(":checked")) {
				$formFaleConoscoME.find('.label_privacy_policy').removeClass('label_privacy_policy_error');
			} else {
				$formFaleConoscoME.find('.label_privacy_policy').addClass('label_privacy_policy_error');
			}
		});

		function showSuccessFaleConosco() {
			$spacer.hide();
			$success.show();
			$alertErrors.hide()

			$('.alert-error-contact-list').html('')
		}

		function onSubmitFaleConosco() {
			var url, data;

			if (!formEnabledFaleConosco) return;

			url = $formFaleConoscoME.attr('action');

			const responseRecaptcha = grecaptcha.getResponse();

			if (responseRecaptcha.length == 0) {
				$captcha.show();

				return
			}

			$captcha.hide();

			//Limpa as classes de erro (se presentes no momento)
			$containerNome.removeClass('cml_error_class_cads');
			$containerEmail.removeClass('cml_error_class_cads');
			$containerTelefone.removeClass('cml_error_class_cads');
			$containerCidade.removeClass('cml_error_class_cads');
			$containerEstado.removeClass('cml_error_class_cads');
			$containerPais.removeClass('cml_error_class_cads');
			$containerMensagem.removeClass('cml_error_class_cads');

			data = {
				nome: $nome.val(),
				email: $email.val(),
				telefone: $telefone.val(),
				cidade: $cidade.val(),
				estado: $estado.val(),
				pais: $pais.val(),
				mensagem: $mensagem.val(),
				privacy_policy: (($privacy_policy.is(":checked")) ? 1 : 0),
			};

			setFormEnabledFaleConosco(false);

			$spacer.show();
			$success.hide();
			$error.hide();
			$alertErrors.hide()

			$('.alert-error-contact-list').html('')

			$.ajax({
				url: url,
				data: data,
				dataType: 'json',
				type: 'post',
				success: function (response, textStatus) {
					if (response.exception) {
						$spacer.hide();
						$error.html($msgErroRequisicao.val()).show();
					} else {
						$error.html($msgErroValidacao.val());
						showSuccessFaleConosco();

						$nome.val('');
						$email.val('');
						$telefone.val('');
						$cidade.val('');
						$estado.val('');
						$pais.val('');
						$mensagem.val('');
						$privacy_policy.prop('checked', false);

						$containerPais.find('.selectSpan').html($strCampoSelecione.val());
					}
				},
				error: function (response, textStatus, error) {
					showErrorsFaleConosco(response.responseJSON);
				},
				complete: function () {
					setFormEnabledFaleConosco(true);
				}
			});
		}

		initFaleConosco();
	}
});